<template>
  <section class="background-section">
    <div class="uk-container">
      <div class="uk-grid-large uk-flex-middle" uk-grid>
        <div class="uk-width-1-2@m uk-visible@m">
          <img
            loading="lazy"
            :src="require('@/assets/imagem-marketing.jpg')"
            alt="Imagem de uma mulher segurando o celular, exibindo as redes sociais com símbolos saindo pela tela."
            class=""
          />
        </div>
        <div class="uk-width-1-2@m margin-mobile">
          <h3 class="uk-text">Marketing</h3>
          <p class="sub-text">
            Nosso Marketing responsável por estabelecer a imagem da marca da
            empresa, atrair e fidelizar clientes.
          </p>
          <div
            class="uk-grid-small uk-text-center uk-child-width-1-3@l uk-child-width-1-3@s uk-child-width-1-2"
            uk-grid
          >
            <div
              v-for="icon in icons"
              :key="icon.alt"
              class="uk-margin-top uk-margin-medium-bottom"
            >
              <img :src="require(`@/assets/${icon.src}`)" :alt="icon.alt" />
              <p class="text-p" v-html="icon.text"></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
  
<script>
export default {
  name: "CustomerSupport",
  data() {
    return {
      icons: [
        {
          src: "campaign.svg",
          alt: "Anúncios nas plataformas Google Ads e Meta",
          text: "Anúncios nas <br />plataformas Google<br />Ads e Meta",
        },
        {
          src: "alternate_email.svg",
          alt: "Email Marketing",
          text: "Email Marketing",
        },
        {
          src: "draft.svg",
          alt: "Material de Apoio",
          text: "Material de Apoio",
        },
        {
          src: "manage_search.svg",
          alt: "Maior Visibilidade",
          text: "Maior Visibilidade",
        },
      ],
    };
  },
};
</script>
  
<style scoped>
.background-section {
  background-color: #ffffff;
}
.uk-text {
  font-size: 32px;
  font-weight: 700;
  line-height: 38.4px;
  text-align: left;
  color: #000000;
}
.sub-text {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #000000cc;
}
.text-p {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: center;
  color: #00000099;
}
@media (max-width: 960px) {
  .margin-mobile {
    margin-top: 50px;
  }
}

@media (max-width: 640px) {
  .text-p {
    margin-bottom: 50px;
  }
  .margin-mobile {
    margin-top: 50px;
  }
}
</style>
