<template>
  <router-view />
</template>

<script>
export default {
  name: "App",
  components: {},
};
</script>

<style >
html {
  font-family: system-ui, --apple-system, "Segoe UI Adjusted", "Segoe UI",
    "Liberation Sans", sans-serif;
  scroll-behavior: smooth;
}
</style>