<template>
  <section class="background-section">
    <div class="uk-container">
      <div class="uk-grid-large uk-flex-middle" uk-grid>
        <div class="uk-width-1-1 uk-width-1-2@m">
          <h3 class="uk-text margin-mobile">Atendimento ao Cliente</h3>
          <p class="sub-text">
            Na Multicom contamos com uma central capacitada para mais de 1000
            contatos por dia para auxiliar nossos clientes.
          </p>
          <div
            class="uk-grid-small uk-child-width-1-3@l uk-child-width-1-3@s uk-child-width-1-2 uk-text-center"
            uk-grid
          >
            <div
              v-for="icon in icons"
              :key="icon.alt"
              class="uk-margin-top margin-bottom-mobile"
            >
              <img :src="require(`@/assets/${icon.src}`)" :alt="icon.alt" />
              <p class="text-p" v-html="icon.text"></p>
            </div>
          </div>
        </div>
        <div class="uk-width-1-1 uk-width-1-2@m uk-visible@m">
          <img
            loading="lazy"
            :src="require('@/assets/imagem-atendimento.jpg')"
            alt="Imagem de uma colaboradora do setor de atendimento ao cliente"
            class="responsive-image"
          />
        </div>
      </div>
    </div>
  </section>
</template>


<script>
export default {
  name: "CustomerSupport",
  data() {
    return {
      icons: [
        {
          src: "client_phone.svg",
          alt: "Dúvidas e Reclamações",
          text: "Dúvidas e<br />Reclamações",
        },
        {
          src: "client_oc.svg",
          alt: "Orçamentos Especiais",
          text: "Orçamentos Especiais",
        },
        {
          src: "client_dv.svg",
          alt: "Auxílio na Compra",
          text: "Auxílio na Compra",
        },
      ],
    };
  },
};
</script>



<style scoped>
.background-section {
  background-color: #f3f6f9;
}
.uk-text {
  font-size: 32px;
  font-weight: 700;
  line-height: 38.4px;
  text-align: left;
  color: #000000;
}
.sub-text {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #000000cc;
}
.text-p {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: center;
  color: #00000099;
}
@media (max-width: 960px) {
  .margin-mobile {
    margin-top: 50px;
  }
  .margin-bottom-mobile {
    margin-bottom: 50px;
  }
  .uk-text {
    text-align: left;
  }
  .sub-text {
    text-align: left;
  }
}
@media (max-width: 640px) {
  .background-section {
    padding: 20px 0;
  }
  .uk-text {
    font-size: 32px;
    line-height: 38.8px;
  }
  .sub-text {
    font-size: 16px;
    line-height: 21px;
  }
  .text-p {
    font-size: 12px;
    line-height: 18px;
  }
  .uk-child-width-1-2 > *:nth-child(2n + 1) {
    clear: left;
  }
}
</style>

