<template>
  <section class="background-section">
    <div class="uk-container">
      <div class="uk-grid-large uk-flex-middle" uk-grid>
        <div class="uk-width-1-2@m uk-visible@m">
          <img
            loading="lazy"
            :src="require('@/assets/imagem-logistica.jpg')"
            alt="Imagem de um homem entregador de encomendas segurando uma caixa e sorrindo."
            class=""
          />
        </div>
        <div class="uk-width-1-2@m margin-mobile">
          <h3 class="uk-text">Logística</h3>
          <p class="sub-text">
            Na Multicom contamos com uma central capacitada para mais de 1000
            contatos por dia para auxiliar nossos clientes.
          </p>
          <div
            class="uk-grid-small uk-text-center uk-child-width-1-3@l uk-child-width-1-3@s uk-child-width-1-2"
            uk-grid
          >
            <div
              v-for="icon in icons"
              :key="icon.alt"
              class="uk-margin-top uk-margin-medium-bottom"
            >
              <img :src="require(`@/assets/${icon.src}`)" :alt="icon.alt" />
              <p class="text-p" v-html="icon.text"></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>


<script>
export default {
  name: "CustomerSupport",
  data() {
    return {
      icons: [
        {
          src: "local_shipping.svg",
          alt: "Entrega em todo o Brasil",
          text: "Entrega em todo o Brasil",
        },
        {
          src: "location.svg",
          alt: "+5000 bases de retirada no país",
          text: "+5000 bases de retirada no país",
        },
        {
          src: "handshake.svg",
          alt: "+300 parceiros de logística",
          text: "+300 parceiros de logística",
        },
      ],
    };
  },
};
</script>



<style scoped>
.background-section {
  background-color: #ffffff;
}
.uk-text {
  font-size: 32px;
  font-weight: 700;
  line-height: 38.4px;
  text-align: left;
  color: #000000;
}
.sub-text {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #000000cc;
}
.text-p {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: center;
  color: #00000099;
}
@media (max-width: 960px) {
  .margin-mobile {
    margin-top: 50px;
  }
}

@media (max-width: 640px) {
  .uk-margin-medium-bottom {
    margin-bottom: 1px !important;
  }
  .text-p {
    margin-bottom: 50px;
  }
  .margin-mobile {
    margin-top: 50px;
  }
}
</style>

