<template>
  <section
    class="uk-height-img uk-flex uk-flex-middle uk-background-cover uk-light"
    uk-img
  >
    <picture>
      <source
        srcset="@/assets/banner-principal-640.webp"
        media="(max-width: 640px)"
      />
      <source
        srcset="@/assets/banner-principal-960.webp"
        media="(min-width: 641px) and (max-width: 960px)"
      />
      <img
        :src="require('@/assets/banner-principal-1440.webp')"
        alt="Background Image"
        class="uk-height-img uk-position-cover"
      />
    </picture>
    <div class="uk-position-relative uk-width-1-1">
      <div class="uk-margin-section uk-container">
        <h1 class="title-text">Aumente suas vendas com a Multicom!</h1>
        <div class="margin-mobile">
          <h2 class="txt-SubTitle">Seu sucesso começa no nosso Marketplace</h2>
        </div>
      </div>
    </div>
  </section>
</template>


<script>
export default {
  name: "HeaderLP",
};
</script>


<style scoped>
.uk-height-img {
  height: 96vh;
  background-size: cover;
  background-position: center;
}

.title-text {
  width: 640px;
  height: 288px;
  font-size: 80px;
  font-weight: 700;
  line-height: 96px;
  text-align: left;
  color: #ffffff;
  z-index: 1;
}

.txt-SubTitle {
  width: 640px;
  height: 24px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #ffffff;
  z-index: 1;
}
.uk-position-cover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
}

.uk-position-relative {
  position: relative;
}

@media (max-width: 640px) {
  .title-text {
    width: 100%;
    font-size: 40px;
    line-height: 48px;
    height: 139px;
  }

  .txt-SubTitle {
    font-size: 16px;
    color: #ffffffcc;
    width: 288px;
  }
}
</style>

