<template>
  <section
    class="uk-section uk-section-default uk-margin-large-top uk-margin-large-bottom"
  >
    <div class="uk-container">
      <div
        class="uk-grid-match uk-child-width-1-1 uk-child-width-1-3@m"
        uk-grid
        v-if="!isMobile"
      >
        <div v-for="(testimonial, index) in testimonials" :key="index">
          <div class="uk-card uk-flex uk-flex-column uk-flex-between">
            <div>
              <h3 class="uk-card-title">{{ testimonial.title }}</h3>
              <p class="uk-card-subTitle">"{{ testimonial.quote }}"</p>
            </div>
            <div class="uk-grid-small uk-flex-middle uk-margin-top" uk-grid>
              <div class="uk-width-auto">
                <img
                  :src="testimonial.image"
                  alt="Customer image"
                  class="uk-border-circle"
                  width="40"
                  height="40"
                />
              </div>
              <div class="uk-width-expand">
                <h5 class="uk-margin-remove-bottom uk-text-testimonial">
                  {{ testimonial.name }}
                </h5>
                <p class="uk-text-meta uk-margin-remove-top">
                  {{ testimonial.position }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <swiper
        v-if="isMobile"
        :slides-per-view="1"
        :pagination="{ clickable: true }"
        class="mySwiper"
      >
        <swiper-slide v-for="(testimonial, index) in testimonials" :key="index">
          <div class="uk-card uk-flex uk-flex-column uk-flex-between">
            <div>
              <h3 class="uk-card-title">{{ testimonial.title }}</h3>
              <p class="uk-card-subTitle">"{{ testimonial.quote }}"</p>
            </div>
            <div class="uk-grid-small uk-flex-middle uk-margin-top" uk-grid>
              <div class="uk-width-auto">
                <img
                  :src="testimonial.image"
                  alt="Imagem cliente"
                  class="uk-border-circle"
                  width="40"
                  height="40"
                />
              </div>
              <div class="uk-width-expand">
                <h5 class="uk-margin-remove-bottom uk-text-testimonial">
                  {{ testimonial.name }}
                </h5>
                <p class="uk-text-meta uk-margin-remove-top">
                  {{ testimonial.position }}
                </p>
              </div>
            </div>
          </div>
        </swiper-slide>
        <div class="swiper-pagination"></div>
      </swiper>
    </div>
  </section>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper-bundle.css";
import SwiperCore, { Pagination, Autoplay } from "swiper";

SwiperCore.use([Pagination, Autoplay]);

export default {
  name: "TestimonialSection",
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      isMobile: window.innerWidth < 961,
      testimonials: [
        {
          title: "Suporte ao Cliente",
          quote:
            "Depois de testar várias plataformas, finalmente encontrei uma que atende a todas as minhas necessidades. A facilidade de uso, o suporte ao cliente eficiente e as inúmeras funcionalidades disponíveis fazem desta a melhor opção do mercado. Recomendo a todos os vendedores que querem profissionalizar suas operações e atingir novos patamares de sucesso.",
          name: "Roberta Miranda",
          position: "Cliente Multicom",
          image: require("../assets/RobertaMiranda.svg"),
        },
        {
          title: "Aumento das Vendas",
          quote:
            "Em poucos meses utilizando a Multicom, notei um crescimento nas minhas vendas. As ferramentas de marketing e análise de desempenho são excelentes e me permitiram alcançar um público maior e mais diversificado. Graças à plataforma, aumentei minhas vendas de maneira impressionante e continuo a crescer!",
          name: "Marcelo Costa",
          position: "Cliente Multicom",
          image: require("../assets/MarceloCosta.svg"),
        },
        {
          title: "Fácil Usabilidade",
          quote:
            "Desde que comecei a usar a plataforma, minha experiência de venda online mudou completamente. A interface é extremamente intuitiva",
          name: "Clarice Souza",
          position: "Cliente Multicom",
          image: require("../assets/ClariceSouza.svg"),
        },
      ],
    };
  },
  created() {
    window.addEventListener("resize", this.handleResize);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize() {
      this.isMobile = window.innerWidth < 641;
    },
  },
};
</script>

<style scoped>
.uk-card-body {
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.uk-card-title {
  font-size: 32px;
  font-weight: 600;
  line-height: 38.4px;
  text-align: left;
  color: #000000;
  margin-bottom: 10px;
}
.uk-card-subTitle {
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  text-align: left;
  color: #000000cc;
}
.uk-text-testimonial {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  color: #000000;
}
.uk-text-meta {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #000000;
}
@media (max-width: 640px) {
  .uk-card-body {
    text-align: center;
  }
}
.mySwiper .swiper-pagination-bullet {
  background: #000;
  opacity: 1;
}
.mySwiper .swiper-pagination-bullet-active {
  background: #007bff;
}
</style>
