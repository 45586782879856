<template>
  <footer class="uk-background uk-padding-small">
    <div class="uk-container">
      <div class="footer-container uk-flex uk-flex-between uk-flex-middle">
        <div class="contact-info">
          <span>
            <a href="tel:+5531987654321" class="uk-text-phone">
              (31) 98765-4321
            </a>
          </span>
          <span>
            <a
              href="mailto:atendimento@multicom.com"
              class="uk-text uk-margin-footer"
            >
              atendimento@multicom.com
            </a>
          </span>
        </div>
        <div class="footer-right">
          <a href="/politica-de-privacidade" class="uk-text"
            >Política de Privacidade</a
          >
          <span class="uk-text uk-margin-footer">Copyright Multicom 2024</span>
        </div>
      </div>
    </div>
  </footer>
</template>

  
<script>
export default {
  name: "FooterLP",
};
</script>
  
<style scoped>
.uk-background {
  background-color: #00112c;
}
.uk-text-phone {
  font-size: 16px;
  font-weight: 400;
  line-height: 19.36px;
  text-align: left;
  color: #ffffff;
}
.uk-text {
  font-size: 16px;
  font-weight: 400;
  line-height: 19.36px;
  text-align: left;
  color: #ffffff;
}
a {
  text-decoration: none;
}
.footer-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.contact-info,
.footer-right {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}
.uk-margin-footer {
  margin-left: 10px;
}

@media (max-width: 960px) {
  .footer-container {
    align-items: flex-start;
  }
  .contact-info,
  .footer-right {
    align-items: flex-start;
  }
  .contact-info span,
  .footer-right a,
  .footer-right span {
    margin-bottom: 5px;
  }
  .uk-margin-footer {
    margin-left: 0;
    margin-top: 5px;
  }
}
@media (min-width: 961px) {
  .footer-container {
    flex-direction: row;
    justify-content: space-between;
  }
  .contact-info,
  .footer-right {
    flex-direction: row;
    margin-top: 0;
  }
}
</style>

  