<template>
    <section>
      <nav class="uk-background-default" uk-sticky>
        <div class="uk-container uk-navbar-left" uk-navbar>
          <a @click="navigateToHome">
            <img class="header-img" :src="headerImg" alt="Logo da Multicom" />
          </a>
        </div>
      </nav>
    </section>
</template>
  
  <script>
import logo from "../assets/logo.svg";

export default {
  name: "HeaderVue",
  data() {
    return {
      headerImg: logo,
    };
  },
  methods: {
    navigateToHome() {
      this.$router.push("/");
    },
  },
};
</script>

 <style scoped>
.uk-background-default {
  background-color: #00112c;
  height: 72px;
  margin-top: -50px;
}

.header-img {
  margin-top: 18px;
}

@media (max-width: 1024px) {
}

@media (min-width: 1025px) {
}
</style>
  