<template>
  <section>
    <div class="uk-container uk-margin-large-top uk-margin-large-bottom">
      <div
        class="uk-position-relative uk-visible-toggle uk-light"
        tabindex="-1"
        uk-slider="finite: false; sets: true"
      >
        <ul
          class="uk-slider-items uk-child-width-1-2@s uk-child-width-1-6@m uk-flex uk-flex-center"
        >
          <li v-for="(feature, index) in features" :key="index">
            <div class="uk-card uk-card-body uk-text-center">
              <img
                :src="feature.icon"
                class="uk-margin-small-bottom"
                :alt="feature.altText"
                width="50"
                height="50"
              />
              <p class="uk-text">{{ feature.text }}</p>
            </div>
          </li>
        </ul>
        <a
          class="uk-position-center-left uk-position-small uk-hidden-hover"
          href="#"
          uk-slidenav-previous
          uk-slider-item="previous"
        ></a>
        <a
          class="uk-position-center-right uk-position-small uk-hidden-hover"
          href="#"
          uk-slidenav-next
          uk-slider-item="next"
        ></a>
      </div>
    </div>
  </section>
</template>

<script>
import payments from "../assets/payments.svg";
import query_stats from "../assets/query_stats.svg";
import computer from "../assets/computer.svg";
import person from "../assets/person.svg";
import image from "../assets/image.svg";
import store from "../assets/store.svg";

export default {
  name: "HeaderLP",
  data() {
    return {
      features: [
        {
          icon: payments,
          text: "+19 mil compradores ativos/mês",
          altText: "Ícone de Dinheiro",
        },
        {
          icon: query_stats,
          text: "+65 mil buscas no Google/mês",
          altText: "Ícone de Lupa",
        },
        {
          icon: computer,
          text: "+65 mil buscas no Google/mês",
          altText: "Ícone de Computador",
        },
        {
          icon: person,
          text: "+65 mil buscas no Google/mês",
          altText: "Ícone de Usuários",
        },
        {
          icon: image,
          text: "+65 mil buscas no Google/mês",
          altText: "Ícone de Imagem",
        },
        {
          icon: store,
          text: "+65 mil buscas no Google/mês",
          altText: "Ícone de Agência",
        },
      ],
    };
  },
};
</script>

<style scoped>
.uk-text {
  color: #000000cc;
}
.uk-card-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 188px;
  width: 95%;
  border-radius: 4px;
  border: 1px solid #0000001f;
  cursor: pointer;
}
.uk-height-img {
  height: 970px;
}
.txt-title {
  width: 640px;
  height: 288px;
  gap: 0px;
  opacity: 0px;

  font-size: 80px;
  font-weight: 700;
  line-height: 96px;
  text-align: left;
  color: #ffffff;
}

.txt-SubTitle {
  width: 640px;
  height: 24px;
  gap: 0px;
  opacity: 0px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #ffffff;
}

.uk-margin-section {
  margin-top: 200px;
}
@media (min-width: 641px) and (max-width: 960px) {
  .uk-child-width-1-2\@s > * {
    width: 35%;
  }
}

@media (max-width: 640px) {
  .uk-child-width-1-2\@s > * {
    width: 200px;
    margin-left: 0px;
  }
  .txt-title {
    font-size: 35px;
    width: 320px;
    line-height: 50px;
  }

  .txt-SubTitle {
    font-size: 20px;
    line-height: 25px;
    width: 350px;
  }
}
</style>
