<template>
  <NavBar />
  <HeaderVue />
  <Cards />
  <History />
  <Testimony />
  <Client />
  <Marketing />
  <Security />
  <Logistics />
  <ItAndDevelopment />
  <FooterLP />
</template>
  
  <script>
import NavBar from "../../components/NavBar.vue";
import HeaderVue from "../../components/HeaderLP.vue";
import Cards from "../../components/Cards.vue";
import History from "../../components/History.vue";
import Testimony from "../../components/TestimonyLP.vue";
import Client from "../../components/Client.vue";
import Marketing from "../../components/Marketing.vue";
import Security from "../../components/Security.vue";
import Logistics from "../../components/Logistics.vue";
import ItAndDevelopment from "../../components/ItAndDevelopment.vue";
import FooterLP from "../../components/FooterLP.vue";

export default {
  name: "App",
  components: {
    NavBar,
    HeaderVue,
    Cards,
    History,
    Testimony,
    Client,
    Marketing,
    Security,
    Logistics,
    ItAndDevelopment,
    FooterLP,
  },
};
</script>
  
  <style >
h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
div,
a,
li,
ul,
ol,
button,
input,
textarea {
  font-family: "Inter", sans-serif;
}
</style>