<template>
  <section class="background-section">
    <div class="uk-container">
      <div class="uk-margin-large-top uk-margin-xlarge-bottom" uk-grid>
        <div class="uk-width-expand@m">
          <h3 class="history-text">Nossa História</h3>
          <div>
            <p class="sub-text">
              Com mais de 20 anos de experiência no mercado, a Multicom se
              consolidou como uma plataforma de marketplace completa e
              confiável. Nossa longa trajetória é marcada pelo compromisso com a
              qualidade, inovação e suporte ao cliente. Na Multicom, oferecemos
              uma infraestrutura robusta e segura para que você possa focar no
              que realmente importa: expandir suas vendas e conquistar novos
              mercados. Nossos serviços incluem ferramentas avançadas de gestão,
              integração com diversas formas de pagamento e um suporte técnico
              qualificado, tudo para garantir que seu negócio prospere no
              ambiente digital.
            </p>
          </div>
        </div>
        <div class="uk-width-expand@m uk-margin-small-top margin-mb">
          <video width="512" height="320" class="w-100" controls="">
            <source
              loading="lazy"
              src="@/assets/video_multicom.mp4"
              type="video/mp4"
            />
          </video>
        </div>
      </div>
    </div>
  </section>
</template>
  
  
  <script>
export default {
  name: "HistoryLP",
  data() {
    return {};
  },
};
</script>
  
<style scoped>
.history-text {
  width: 228px;
  height: 38px;

  font-weight: 700;
  font-size: 32px;
  line-height: 38.4px;
  color: #000000;
}
.sub-text {
  width: 480px;
  height: 264px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #000000cc;
}

.background-section {
  background-color: #f3f6f9;
}

@media (max-width: 640px) {
  .sub-text {
    width: 100%;
    font-size: 16px;
  }

  .margin-mb {
    margin-top: 60px !important;
  }
}

@media (min-width: 641px) and (max-width: 960px) {
  .sub-text {
    width: 100%;
    font-size: 16px;
  }
  .margin-mb {
    margin-top: 0px !important;
    margin-left: 100px;
  }
}
</style>
  