<template>
  <section class="background-section">
    <div class="uk-container">
      <div class="uk-grid-large uk-flex-middle" uk-grid>
        <div class="uk-width-1-2@m margin-mobile">
          <h3 class="uk-text">Segurança</h3>
          <p class="sub-text">
            Nosso sistema conta com diversas formas de pagamento para fazer do
            seu produto um campeão de vendas, com um sistema antifraude
            garantido!
          </p>
          <div class="icon-container">
            <div v-for="icon in icons" :key="icon.alt" class="icon-item">
              <img :src="require(`@/assets/${icon.src}`)" :alt="icon.alt" />
              <p class="text-p">{{ icon.text }}</p>
            </div>
          </div>
        </div>
        <div class="uk-width-1-2@m uk-visible@m">
          <img
            loading="lazy"
            :src="require('@/assets/imagem-segurança.jpg')"
            alt="Imagem de um celular protegido com segurança."
            class=""
          />
        </div>
      </div>
    </div>
  </section>
</template>


<script>
export default {
  name: "CustomerSupport",
  data() {
    return {
      icons: [
        {
          src: "pix.svg",
          alt: "Pix",
          text: "Pix",
        },
        {
          src: "credit_card.svg",
          alt: "Cartão de Débito/Crédito",
          text: "Cartão de Débito/Crédito",
        },
        {
          src: "barcode.svg",
          alt: "Boleto Bancário",
          text: "Boleto Bancário",
        },
        {
          src: "currency_exchange.svg",
          alt: "Transferência Bancária",
          text: "Transferência Bancária",
        },
      ],
    };
  },
};
</script>


<style scoped>
.background-section {
  background-color: #f3f6f9;
}
.uk-text {
  font-size: 32px;
  font-weight: 700;
  line-height: 38.4px;
  text-align: left;
  color: #000000;
}
.sub-text {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #000000cc;
}
.icon-container {
  display: flex;
  flex-wrap: wrap;
  margin-top: 55px;
}
.icon-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 20px;
  margin-bottom: 40px;
}
.text-p {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: center;
  width: 120px;
  color: #00000099;
}
@media (max-width: 960px) {
  .margin-mobile {
    margin-top: 50px;
  }
  .icon-container {
    margin-left: 50px;
  }
}

@media (max-width: 640px) {
  .icon-container {
    margin-left: -15px;
  }
  .text-p {
    margin-bottom: 20px;
  }
  .icon-container {
    justify-content: center;
  }
  .icon-item {
    flex: 0 0 45%;
    margin-right: 5%;
    margin-bottom: 20px;
  }
  .icon-item:nth-child(2n) {
    margin-right: 0;
  }
  .margin-mobile {
    margin-top: 50px;
  }
}
</style>
